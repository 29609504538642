@font-face {
  font-family: "ANextRegular";
  src: url("./mc-fonts/AvenirNext-Regular.ttf");
}
@font-face {
  font-family: "ANextBold";
  src: url("./mc-fonts/AvenirNext-Bold.ttf");
}
@font-face {
  font-family: "ANextMedium";
  src: url("./mc-fonts/AvenirNext-Medium.ttf");
}
* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'ANextRegular';
}
body {
  background-color: #fafafa;
}
.App-header {
  display: flex;
  justify-content: flex-end;
  height: 50px;
  align-items: center;
  padding: 1rem;
  background-color: #b58270;
}
.header-item {
  text-transform: uppercase;
  padding: 0 1rem;
}
.about-left {
  overflow: hidden;
  width: 100vw;
  padding: 2rem;
  padding-bottom: 0;
}
.about-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: "ANextBold";
  margin-top: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0.25rem 1rem;
  background-color: #6d98bc;
  border-radius: 0.25rem;
}
.about-title.first {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  margin-left: 0;
}
.about-title.first .profile-image {
  width: 90px;
  border-radius: 100px;
  border: solid 0px white;
  /* position: absolute; */
  /* left: 1rem; */
  /* top: -25px; */
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.23);
  margin: -4rem 1.25rem -4rem -24px;
}
.about-title.first .name {
  font-size: 2rem;
  font-family: "ANextBold";
  justify-self: flex-start;
  width: 100%;
}
.about-title.first .contact {
  font-size: 0.8rem;
  text-align: right;
}
.about-title.first .contact a {
  display: block;
  color: black;
}
.about-subtitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.about-text {
  text-indent: 2rem;
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
}
.about-right {
  padding: 2rem;
}
.tiles-container {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
}
.tile {
  display: inline-block;
  margin: 0rem auto 2rem;
  cursor: pointer;
}
.tile:hover .tile-text {
  text-decoration: underline #0094ff;
}
img.tile-image {
  width: 230px;
  height: 230px;
  /* background-size: unset; */
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.3s;
}
img.tile-image:hover {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
}
.tile-text {
  text-transform: uppercase;
  letter-spacing: 1px;
}
.project-images {
  width: 50%;
  padding: 1rem 1rem 1rem 2rem;
}
.displayed-image {
  width: 100%;
  margin: 0 0rem 1rem;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.25);
}
.displayed-image:last-child {
  margin-bottom: 0;
}
.project-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: "ANextBold";
  margin-top: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0.25rem 1rem;
  background-color: #cccccc;
  border-radius: 0.25rem;
  position: relative;
}
.project-title.has-go {
  padding-right: 1.5rem;
}
.project-title.sub {
  margin: 1rem 0;
  width: max-content;
  font-family: ANextMedium;
  font-size: 1.25rem;
}
.project-title.sub.two {
  padding: 0;
  background-color: unset;
  text-decoration: underline;
}
.project-right {
  width: 50%;
  padding: 2rem;
}
.about-project {
  display: flex;
  padding-top: 1rem;
  border-top: solid 1px lightgray;
  flex-direction: row;
}
.about-project.flip {
  flex-direction: row-reverse;
}
.levaty {
  background-color: #d98219;
  text-decoration-color: #d98219 !important;
}
.monj {
  background-color: #20a182;
  text-decoration-color: #20a182 !important;
}
.multipop {
  background-color: lightcoral;
  text-decoration-color: lightcoral !important;
}
.tca {
  background-color: #43a7d0;
  text-decoration-color: #43a7d0 !important;
}
.shift {
  background-color: #d55089;
  text-decoration-color: #d55089 !important;
}
.project-text {
  text-indent: 2rem;
  margin-bottom: 0.5rem;
}
.roi {
  background-color: #223758;
  text-decoration-color: #223758 !important;
  color: white;
}
.roi.sub.two {
  color: black;
}
.roi .go-icon path {
  fill: white;
}
.ae {
  background-color: #0f2c3a;
  text-decoration-color: #0f2c3a !important;
  color: white;
}
.ae.sub.two {
  color: black;
}
.ae .go-icon path {
  fill: white;
}
.prf {
  background-color: #cea99d;
  text-decoration-color: #cea99d !important;
}
.mtop {
  margin-top: 1rem;
}
.go-icon {
  position: absolute;
  height: 17px;
  top: 0.5rem;
  right: 0.5rem;
  transform: scaleX(-1);
  cursor: pointer;
}
@media only screen and (max-width: 825px) {
  .about-project {
    display: flex;
    flex-direction: column;
    border-top: solid 16px lightgray;
  }
  .about-project.flip {
    flex-direction: column;
  }
  .project-images {
    width: 100%;
    padding: 1rem 2rem 1rem 2rem;
  }
  .project-right {
    width: 100%;
    padding: 2rem;
  }
}
@media only screen and (max-width: 675px) {
  .about-title.first {
    display: block;
    margin-top: -1rem;
    margin-left: -1rem;
  }
  .about-title.first .profile-image {
    margin: 0 auto;
  }
  .about-title.first > * {
    margin: auto;
    display: block;
    text-align: center !important;
  }
}
